const validationRules = {
  required: (value, param) => {
    return (value ? null : 'This field is required.');
  },
  max_length: (value, max_length) => {
    let noOfChar = value.length
    if (noOfChar > max_length) {
      return `Cannot exceed more than ${max_length} characters `
    }
    return null;
  },
  min: (value, min) =>
    value && parseFloat(value) >= min
      ? null
      : `Minimum length is ${min}.`,
  min_value: (value, min) =>
    value && parseFloat(value) >= min
      ? null
      : `Minimum value is ${min}.`,
  max: (value, max) =>
    value && parseFloat(value) <= max
      ? null
      : `Maximum length is ${max}.`,
  max_value: (value, max) =>
    value && parseFloat(value) <= max
      ? null
      : `Maximum value is ${max}.`,
  email: (value, param) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
      ? null
      : 'Invalid email address.',
  above_zero: (value, param) => value <= 0 ? "This value should be more than zero." : null,
  decimal: (value, maxDecimalPlaces) => {
    let regex;
    if (maxDecimalPlaces == 0) {
      // Match integers only
      regex = /^-?\d+$/; // Allow negative and positive integers
    }
    else {
      regex = new RegExp(`^-?(\\d*\\.\\d{1,${maxDecimalPlaces}}|\\d+)$`);
    }
    if (!regex.test(value)) {
      return `Value can have up to ${maxDecimalPlaces} decimal places.`;
    }
    else
      return null;
  },
  numeric: (value, param) => {
    const numericRegex = /^[+-]?\d+$/; // Matches only integers
    if (!numericRegex.test(value)) {
      return 'This field must be a numeric value.';
    }
    return null;
  },
  no_leading_comma: (value, param) => {
    // Check for leading comma
    let errorMsg = "The field value can only be 0 or above.";
    value = String(value);
    if (value.startsWith(',')) {
      return "The value cannot start with leading commas";
    }
    // Check for multiple commas
    if (value.split(',').length > 1) {
      return errorMsg;
    }
    if (value.split('.').length > 2) {
      return errorMsg;
    }
    return null;

  },
  mimes: (value, param) => {
    // for the 'file' type of input
    // right now only for images
    // accepting params in the form of -> image/jpeg,image/png,image/webp
    let result = {}
    let isTypeMatched = false;
    param.split(',').forEach(type => {
      const [category, format] = type.split('/');
      if (!result[category]) {
        result[category] = [];
      }
      result[category].push(format);
    });
    // if(value.includes('image')){
    result['image'].forEach(eachtype => {
      if (value.includes(eachtype)) {
        isTypeMatched = true;
      }
    })
    // }
    if (!isTypeMatched) {
      return "This field must have a valid file type."
    }
    return null;
  },
  integer: (value, param) => {
    const integerRegex = /^-?\d+$/; // Matches positive & negative whole numbers only
    if (!integerRegex.test(value)) {
      return "This field must be an integer.";
    }
    return null;
  }
};

export function validateField(rules, value, args = {}) {
  if (rules == true) {
    rules = {} // its because inside input-length file sometimes as a validation rule, true is being returned
  }
  // when rules coming in the form of object
  let isRequiredRule;
  if (typeof rules == 'object') {
    if (!Boolean(rules)) {
      rules = {}
    }
    let finalRulesArray = []
    let rulesArray = Object.keys(rules)
    isRequiredRule = rulesArray.includes('required')
    let param, finalValue;
    for (let i = 0; i < rulesArray.length; i++) {
      let key = rulesArray[i]
      param = rules[key]
      if (key == 'between') {  // special case for between because of nested json
        let min = param['min']
        let max = param['max']
        finalValue = `min_value:${min}`
        finalRulesArray.push(finalValue)
        finalValue = `max_value:${max}`
        finalRulesArray.push(finalValue)
      }
      else {
        finalValue = key + ":" + param
        finalRulesArray.push(finalValue)
      }
    }
    rules = [...finalRulesArray]
  }
  else {
    // when rules coming in the form of string
    rules = rules.split('|');
    if (!Array.isArray(rules)) {
      rules = [...rules]
    }
    isRequiredRule = rules.includes('required')
  }
  if (!value && !isRequiredRule) { // if there is no value and required rule is not present then no need to check any error
    return null;
  }
  const ruleList = rules
  for (const rule of ruleList) {
    let ruleName, param = null;
    [ruleName, param] = rule.split(':');
    const validator = validationRules[ruleName];
    if (validator) {
      if (value == 'custom') {
        return null; // its because in input-length, for few fields, the value is 'custom'
      }
      const error = validator(value, param);
      if (error) {
        return error; // Return the first error found
      }
    }
  }
  return null; // No errors
}

export function triggerValidation(refName, currentErrors) {
  let values = Object.values(currentErrors)
  if (values.includes('Please enter the length in x ft y in format')) {
    return currentErrors;  // If already format issue, then dont need to check any other issue
  }
  let rules, fieldName, input;
  let modelValue = null;
  let errorsObj = { ...currentErrors }
  // Access the element using ref
  let refElement = this.$refs[refName];
  // this condition is wierd but sometimes its coming in the form of an array and sometimes just normal object
  if (Array.isArray(refElement)) {
    refElement = refElement[0]
  }
  // in some cases, input.value will not work so using ref.modelValue
  modelValue = refElement.modelValue;
  // here the type of refElement.$el.classList is an object in case of el-select
  //  { 
  //   0: "el-select"
  //   1: "validatable"
  //   length: 2
  //   value: "el-select validatable"
  //  }
  // so took all the values and checked in el-select/el-date-picker is present or not
  if (refElement?.$el?.classList && (Object.values(refElement?.$el?.classList).includes('el-select') ||
    Object.values(refElement?.$el?.classList).includes('el-date-editor'))) {
    rules = JSON.parse(refElement?.$el?.dataset.rules || '{}')
    input = refElement.$el.querySelector('input')
  }
  else {
    // Check if the ref is an Element UI input (el-input) or a normal input
    input = refElement?.$el
      ? refElement?.$el.querySelector('input') || refElement?.$el.querySelector('textarea') // For el-input
      : refElement; // For normal input

    rules = JSON.parse(input.dataset.rules || '{}'); // Retrieve rules
  }
  fieldName = input.name // Retrieve field name
  const value = input.value || modelValue;
  let minOrMaxValue = null;
  let errorMsg = validateField(rules, value, minOrMaxValue)
  if (errorMsg) {
    errorsObj[fieldName] = errorMsg
  }
  else {
    delete errorsObj[fieldName]
  }
  return errorsObj
}

export function validateAllInputs(currentErrors) {
  // Query all inputs with the 'validatable' class
  let errorsObj = { ...currentErrors }
  let curComponentElement = this?.$el || document
  const elInputs = curComponentElement.querySelectorAll('.validatable');
  let input, fieldName, rules, value, modelValue;

  elInputs.forEach((elInput) => {
    // for el-select / el-date-picker
    if (elInput?.classList && Object.values(elInput?.classList).includes('el-select') ||
      Object.values(elInput?.classList).includes('el-date-editor')) {
      if (!Boolean(elInput?.dataset.rules)) {
        return;
      }
      rules = JSON.parse(elInput?.dataset.rules || '{}')
      input = elInput.querySelector('input')
      // this.$refs[input.name].modelValue
      if (!Boolean(this.$refs[input.name])) {
        return;
      }
      // in some cases, input.value will not work so using ref.modelValue
      modelValue = this.$refs[input.name].modelValue;
    }
    // for normal input
    else if (elInput.tagName == 'INPUT' || elInput.tagName == 'TEXTAREA') {
      input = elInput;   // normal input
      rules = JSON.parse(input.getAttribute('data-rules') || '{}'); // Get data-rules
    }
    // for el-input
    else {
      input = elInput.querySelector('input') || elInput.querySelector('textarea');   // element UI's Input so actual input is wrapped inside elInput
      rules = JSON.parse(input.getAttribute('data-rules') || '{}'); // Get data-rules
    }
    fieldName = input.getAttribute('name'); // Get the field name
    value = input.value || modelValue; // Get the input value
    let minOrMaxValue = null;
    let errorMsg = validateField(rules, value, minOrMaxValue)
    if (errorMsg) {
      errorsObj[fieldName] = errorMsg
    }
    else {
      delete errorsObj[fieldName]
    }
  });
  return errorsObj
}

export function isAllInputsValidOnSubmit(currentErrors) {
  let errorsObj = this.$validateAllInputs(currentErrors)
  this.errors = Object.assign(errorsObj || {})
  if (Object.keys(this.errors).length == 0)
    return true;
  else
    return false
}

export function triggerValidationOnInput(refName, currentErrors) {
  setTimeout(() => {
    let errorsObj = this.$triggerValidation(refName, currentErrors)
    this.errors = Object.assign(errorsObj || {})
  }, 0)
}
